
/* Button styling in forms */
.ant-btn {
    background-color: #444; /* Dark background for buttons */
    border-color: #666; /* Darker border for buttons */
    color: white; /* White text for buttons */
}

/* Add spacing between form items */
.ant-form-item {
    margin-right: 20px; /* Right margin for form items */
    margin-bottom: 20px; /* Bottom margin for form items */
}

/* Ensuring form item labels are visible */
.ant-form-item-label > label {
    color: white !important; /* Important to override default styles */
}


/* Adjust form item label visibility across all forms */
.ant-form-item-label > label {
  color: white !important; /* Ensure labels are always white */
}

/* Customizing Ant Design DatePicker input */
.ant-picker-input > input {
    background-color: white !important;
    color: black !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    background-color: white !important;
    color: black !important;
}