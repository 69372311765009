.spinner-list-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* Keeps the overflow managed within the container */
    display: flex;
    justify-content: center; /* Centers the spinner horizontally */
    height: auto; /* Adjust height dynamically based on content */
    padding: 20px 0; /* Top and bottom padding */
}

.spinner-list {
    list-style: none;
    display: flex; /* Inline-flex to line up horizontally */
    padding: 0;
    margin: 0;
    white-space: nowrap; /* Ensures the items don't wrap */
}

.spinner-item {
    width: 300px; /* Increased width */
    min-height: 250px; /* Minimum height to accommodate more content */
    margin: 10px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Aligns children (text, images) in the center */
    background: #fff;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden; /* Hides anything outside the border radius */
}

.item-image {
    width: 100px; /* Standardizes image width */
    height: 100px; /* Standardizes image height */
    object-fit: cover; /* Ensures images cover the area properly */
    margin: 5px;
}

.card-header {
    width: 100%; /* Takes the full width of the item box */
    text-align: center; /* Centers the header text */
    margin-bottom: 10px; /* Adds space below the header */
}

.card-header h3 {
    font-size: 16px; /* Larger font size for visibility */
    margin: 5px 0; /* Top and bottom margin */
}

.card-header p {
    font-size: 14px; /* Slightly smaller font size for secondary info */
    color: #666; /* Dark gray color for less emphasis */
    margin: 0; /* Removes default paragraph margin */
}

.selection-pin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%; /* Center line */
    border-left: 2px solid red; /* Red line */
    height: 100%; /* Extends from top to bottom */
    pointer-events: none; /* Allows interaction with items behind the line */
}
