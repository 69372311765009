/* index.css or App.css */
body {
  background-color: #1a1a1a; /* Dark background */
  color: white; /* Light text */
  font-family: 'Arial', sans-serif; /* Ensuring text is legible */
}

/* Styles for inputs to make them visible on a dark background */
input, button {
  background-color: #333;
  border: 1px solid #444;
  color: white;
}

/* Placeholder color */
::placeholder {
  color: #aaa;
}
